import React, { useState } from 'react';

export const RefreshContext = React.createContext({
  mrzRefresh: 0,
  triggerMrzScannedRefresh: () => {},
});

export const RefreshProvider = ({ children }: { children: any }) => {
  const [mrzRefresh, setMrzRefresh] = useState(0);

  const triggerMrzScannedRefresh = () => setMrzRefresh((prev) => prev + 1);

  return (
    <RefreshContext.Provider value={{ mrzRefresh, triggerMrzScannedRefresh }}>
      {children}
    </RefreshContext.Provider>
  );
};
