import { useMemo, useState } from 'react';
import {
  Button,
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { Form, Formik, Field } from 'formik';
import { useAsync, useAsyncCallback } from 'react-async-hook';
import dayjs from 'dayjs';

import { IdentificationMeetingHistory, apiClient } from 'api';
import { Card } from 'components';
import { Select } from './form/Select';
import { IdentificationMeetingStatus } from 'types';

const getIdentificationMeetingStatusOptions = () => {
  return Object.values(IdentificationMeetingStatus).map((val) => ({
    label: val,
    value: val,
  }));
};

export const IdentificationMeetingHistoryWidget = () => {
  const { id, tenantId } = useParams<{ id: string; tenantId: string }>();

  const [history, setHistory] = useState<IdentificationMeetingHistory[] | null>(null);

  const identificationMeetingStatusOptions = useMemo(
    () => getIdentificationMeetingStatusOptions(),
    [],
  );

  const { loading, execute } = useAsync(async () => {
    const { data } = await apiClient.api.kycRecordControllerFindAllIdentificationMeeting(id, {
      headers: { 'x-tenant-id': tenantId },
    });

    setHistory(data as IdentificationMeetingHistory[]);
  }, [id]);

  const updateStatus = useAsyncCallback(
    async ({ status }: { status?: IdentificationMeetingStatus }) => {
      if (status) {
        await apiClient.api.kycRecordControllerCreateIdentificationMeeting(
          id,
          { status },
          {
            headers: { 'x-tenant-id': tenantId },
          },
        );
        await execute();
      }
    },
  );
  return (
    <Grid item xs={12} sx={{marginTop: '16px'}}>
          <Formik
            enableReinitialize
            initialValues={{
              status: history?.[0]?.status as IdentificationMeetingStatus,
            }}
            onSubmit={updateStatus.execute}
          >
            <Form>
              <Box display="flex" flexDirection="row" alignItems="flex-end" gap={4}>
                <Field
                  id="status"
                  name="status"
                  label="Identification Meeting Status"
                  placeholder="Please select"
                  component={Select}
                  options={identificationMeetingStatusOptions}
                />
                <Button type="submit">Save</Button>
              </Box>
            </Form>
          </Formik>
          {!!history?.length && (
            <Box sx={{marginTop: '24px'}}>
              <Typography variant="subtitle2">Change history</Typography>
              <TableContainer sx={{ maxHeight: '500px' }}>
                <Table>
                  <TableHead>
                    <TableRow></TableRow>
                    <TableCell>Status</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Modified by</TableCell>
                  </TableHead>
                  <TableBody>
                    {history?.map((h) => (
                      <TableRow>
                        <TableCell>{h.status}</TableCell>
                        <TableCell>{dayjs(h.created).format('DD.MM.YYYY HH:mm')}</TableCell>
                        <TableCell>{h.createdBy}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          )}
    </Grid>
  );
};
