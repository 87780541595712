import React, { useEffect, useState, useContext } from 'react';
import { useAsync, useAsyncCallback } from 'react-async-hook';
import { MdFileDownload, MdFileUpload, MdInsertDriveFile } from 'react-icons/md';
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Paper,
  Stack,
  Typography,
  styled,
} from '@mui/material';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { IoMdEye, IoMdCard } from 'react-icons/io';

import { default as FormDataLib } from 'form-data';
import { FileMetaAdmin, SearchResultAdmin } from 'document/client';
import { Card } from 'components';
import { downloadFile, openFileInBrowser, viewableExtensions } from 'utils';
import dayjs from 'dayjs';
import { apiClient, RequestParams } from 'api';
import { RefreshContext } from '../RefreshContext';
interface Props {
  kycRecordId: string;
  tenantId: string;
}
enum FileAction {
  DOWNLOAD = 'DOWNLOAD',
  VIEW = 'VIEW',
  SCAN_MRZ = 'SCAN_MRZ',
}

export const KycFileAction = ({ kycRecordId, tenantId }: Props) => {
  const documentApiClient = window.documentApiClient;
  const [documents, setDocuments] = useState<FileMetaAdmin[]>([]);

  const { triggerMrzScannedRefresh } = useContext(RefreshContext);

  const loadDocument = useAsyncCallback(async () => {
    const { data } = await documentApiClient.api.adminApiControllerList({
      searchCriteria: {
        documentType: 'KYC Record',
        relationType: 'KycRecord',
        relationId: kycRecordId,
        fileNamePartial: '',
        tenantId,
      },
      orderCriteria: [{ name: 'created', order: 'DESC' }],
    });

    let files = (data as unknown as SearchResultAdmin)?.files;
    files && setDocuments(files);
    return data;
  });

  const downloadDocument = useAsyncCallback(
    async (document, documentAction: FileAction, kycRecordId?: string) => {
      if (document) {
        const { data } = await documentApiClient.api.adminApiControllerDownload(document.id, {
          format: 'arraybuffer',
        });

        const dataText = data as unknown as string;
        if (documentAction === FileAction.VIEW) {
          openFileInBrowser(dataText, document.name);
        }
        if (documentAction === FileAction.DOWNLOAD) {
          downloadFile(dataText, document.name);
        }
        if (documentAction === FileAction.SCAN_MRZ) {
          const file = new File([dataText], document.name, { type: 'application/octet-stream' });

          await apiClient.api.mrzControllerScanMrz(kycRecordId as string, {
            file,
          });
        }
      }
    },
  );

  const downloadZip = useAsyncCallback(async () => {
    const { data } = await documentApiClient.api.adminApiControllerDownloadZip(
      {
        searchCriteria: {
          documentType: 'KYC Record',
          relationType: 'KycRecord',
          relationId: kycRecordId,
          fileNamePartial: '',
          tenantId,
        },
        orderCriteria: [{ name: 'created', order: 'DESC' }],
      },
      { format: 'arraybuffer' },
    );

    if (typeof data === 'string' || (data as any) instanceof ArrayBuffer) {
      downloadFile(data as unknown as string, `KycRecord_${kycRecordId}.zip`);
    }
  });

  useEffect(() => {
    loadDocument.execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { result: documentsLink, execute: reloadLink } = useAsync(async () => {
    if (kycRecordId) {
      // @ts-ignore
      const { data }: AxiosResponse<string> =
        await documentApiClient.api.adminApiControllerGetLinkToDirectory({
          documentType: 'KYC Record',
          relation: 'KycRecord',
          relationId: kycRecordId,
          tenantId,
        });
      return data;
    }
    return null;
  }, [kycRecordId]);

  const uploadDocument = useAsyncCallback(async () => {
    const fileInput = window.document.createElement('input');
    fileInput.type = 'file';
    fileInput.multiple = true; // Allow multiple file selection

    fileInput.addEventListener('change', async (event) => {
      const files = Array.from((event.target as HTMLInputElement).files || []);
      if (files.length > 0) {
        const formData = new FormDataLib();
        formData.append(
          'content',
          files[0], // Upload the first file sequentially
          { filename: files[0].name },
        );
        formData.append(
          'meta',
          JSON.stringify({
            name: files[0].name,
            documentType: 'KYC Record',
            relation: 'KycRecord',
            relationId: kycRecordId,
            tenantId: tenantId,
          }),
        );

        try {
          await documentApiClient.api.adminApiControllerUploadFile(formData as any, {
            // @ts-ignore
            type: null,
          });
          console.log('First file uploaded successfully!');

          // Upload the remaining files simultaneously
          const remainingFiles = files.slice(1);
          const uploadPromises = remainingFiles.map(async (file) => {
            const formData = new FormDataLib();
            formData.append('content', file, {
              filename: file.name,
            });
            formData.append(
              'meta',
              JSON.stringify({
                name: file.name,
                documentType: 'KYC Record',
                relation: 'KycRecord',
                relationId: kycRecordId,
                tenantId: tenantId,
              }),
            );
            return documentApiClient.api.adminApiControllerUploadFile(formData as any, {
              // @ts-ignore
              type: null,
            });
          });

          await Promise.all(uploadPromises);
          console.log('All files uploaded successfully!');
          loadDocument.execute();
          reloadLink();
        } catch (error) {
          console.error('Error uploading files:', error);
        }
      }
    });

    fileInput.click();
  });

  return (
    <Card>
      <Card.Header title="Files" />

      <Card.Body>
        <Stack direction="row" gap="24px">
          <Button
            onClick={uploadDocument.execute}
            disabled={uploadDocument.loading}
            endIcon={<MdFileUpload />}
          >
            Upload
          </Button>

          <Button
            disabled={!documentsLink}
            href={documentsLink || '#'}
            target="_blank"
            endIcon={<FaExternalLinkAlt />}
          >
            See in Drive
          </Button>

          <Button
            disabled={!documents?.length || downloadZip.loading}
            onClick={downloadZip.execute}
            endIcon={<MdFileDownload />}
          >
            Download Zip
          </Button>
        </Stack>

        {documents.length > 0 && (
          <>
            <Divider sx={{ margin: '16px 0' }} />

            <Grid container spacing="24px">
              {documents.map((doc) => {
                const extension = doc.name.split('.').pop()?.toLowerCase() || '';
                const isViewable = extension in viewableExtensions;

                return (
                  <Grid item xs={4}>
                    <StyledDocumentCard variant="outlined">
                      <MdInsertDriveFile size={28} />

                      <Box sx={{ flexGrow: 1 }}>
                        <Typography variant="subtitle1">{doc.name}</Typography>
                        <Typography variant="caption" component="p">
                          {dayjs(doc.created).format('DD.MM.YYYY, HH:mm')}
                        </Typography>
                      </Box>

                      <Box sx={{ display: 'flex', gap: '16px', marginRight: '0' }}>
                        <IconButton size="small" edge="end">
                          <MdFileDownload
                            size="20"
                            onClick={() => downloadDocument.execute(doc, FileAction.DOWNLOAD)}
                          />
                        </IconButton>

                        {isViewable && (
                          <IconButton size="small" edge="start">
                            <IoMdEye
                              size="20"
                              onClick={() => downloadDocument.execute(doc, FileAction.VIEW)}
                            />
                          </IconButton>
                        )}

                        <IconButton size="small" edge="start" disabled={downloadDocument.loading}>
                          <IoMdCard
                            size="20"
                            title="Scan MRZ data"
                            onClick={async () => {
                              await downloadDocument.execute(doc, FileAction.SCAN_MRZ, kycRecordId);
                              triggerMrzScannedRefresh();
                              setTimeout(() => {
                                const mrzScannedDataHistory =
                                  document.getElementById('mrz_scanned_data');
                                if (mrzScannedDataHistory)
                                  mrzScannedDataHistory.scrollIntoView({
                                    block: 'start',
                                    behavior: 'smooth',
                                  });
                              }, 200);
                            }}
                          />
                        </IconButton>
                      </Box>
                    </StyledDocumentCard>
                  </Grid>
                );
              })}
            </Grid>
          </>
        )}
      </Card.Body>
    </Card>
  );
};

const StyledDocumentCard = styled(Paper)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '8px',
  padding: '8px',
});
