/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export enum BeneficialOwnerStatus {
  HOLDINGMORETHAN25SHARES = 'HOLDING_MORE_THAN_25_SHARES',
  CONTROLLING_IN_OTHER_WAYS = 'CONTROLLING_IN_OTHER_WAYS',
  MANAGING_DIRECTORS = 'MANAGING_DIRECTORS',
}

export enum InvestorCategory {
  FAMILY_OFFICE = 'FAMILY_OFFICE',
  UHNWI = 'UHNWI',
  HNWI = 'HNWI',
  RETAIL_INVESTOR = 'RETAIL_INVESTOR',
  OTHER_PRIVATE = 'OTHER_PRIVATE',
  ASSET_MANAGER = 'ASSET_MANAGER',
  MUTUAL_FUND = 'MUTUAL_FUND',
  PENSION_FUND = 'PENSION_FUND',
  HEDGE_FUND = 'HEDGE_FUND',
  OTHER_FUND = 'OTHER_FUND',
  BANK = 'BANK',
  OTHER_FINANCIAL_INSTITUTION = 'OTHER_FINANCIAL_INSTITUTION',
  TRUST = 'TRUST',
  OTHER = 'OTHER',
  SENIOR_INVESTOR = 'SENIOR_INVESTOR',
  JUNIOR_INVESTOR = 'JUNIOR_INVESTOR',
}

export enum YesNo {
  Yes = 'yes',
  No = 'no',
}

export enum PrivateAnnualIncome {
  LESS100K = 'LESS_100K',
  Value100K200K = '100K_200K',
  Value200K500K = '200K_500K',
  MORE500K = 'MORE_500K',
}

export enum EmploymentStatus {
  EMPLOYED = 'EMPLOYED',
  INDEPENDENT = 'INDEPENDENT',
  STUDENT = 'STUDENT',
  PENSIONER = 'PENSIONER',
  IV_PENSIONER = 'IV_PENSIONER',
  UNEMPLOYED = 'UNEMPLOYED',
}

export enum EducationLevel {
  BASIC_EDUCATION = 'BASIC_EDUCATION',
  VOCATIONAL = 'VOCATIONAL',
  SECONDARY_EDUCATION = 'SECONDARY_EDUCATION',
  HIGHER_VOCATIONAL = 'HIGHER_VOCATIONAL',
  BACHELOR = 'BACHELOR',
  MASTER_DR = 'MASTER_DR',
}

export enum PrivateInvestingPlan {
  LESS100K = 'LESS_100K',
  Value100K250K = '100K_250K',
  MORE250K = 'MORE_250K',
}

export enum PrivateNetWorth {
  LESS100K = 'LESS_100K',
  Value100K500K = '100K_500K',
  Value500K2M = '500K_2M',
  MORE2M = 'MORE_2M',
}

export interface EntityMetadataEntity {
  /** @format date-time */
  created: string;
  /** @format date-time */
  updated: string;
  version: number;
}

export interface TenantEntity {
  id: string;
  authKey: string;
  meta: EntityMetadataEntity;
}

export enum PEPCheckStatus {
  NO_HIT = 'NO_HIT',
  HIT = 'HIT',
  OPEN = 'OPEN',
  UNKNOWN = 'UNKNOWN',
  NO_HIT_OLD = 'NO_HIT_OLD',
  HIT_BUT_OK = 'HIT_BUT_OK',
  ADVANON_CHECKED = 'ADVANON_CHECKED',
}

export interface BankVerificationHistory {
  id: string;
  kycRecord: KycRecord;
  kycRecordId: string;
  status: 'OPEN' | 'APPROVED' | 'DECLINED' | 'ADVANON_CHECKED' | 'NOT_NEEDED';
  createdBy: string;
  /** @format date-time */
  created: string;
}

export interface AdminApprovalHistory {
  id: string;
  kycRecord: KycRecord;
  kycRecordId: string;
  status:
    | 'TODO'
    | 'APPROVED'
    | 'PENDING'
    | 'REJECTED'
    | 'EXPIRED'
    | 'INCOMPLETE'
    | 'ADVANON_CHECKED';
  createdBy: string;
  /** @format date-time */
  created: string;
}

export interface OnlineIdentificationHistory {
  id: string;
  kycRecord: KycRecord;
  kycRecordId: string;
  status: 'OPEN' | 'PENDING' | 'IN_REVIEW' | 'APPROVED' | 'REJECTED';
  comment?: string;
  createdBy: string;
  /** @format date-time */
  created: string;
}

export interface IdentificationMeetingHistory {
  id: string;
  kycRecord: KycRecord;
  kycRecordId: string;
  status: 'OPEN' | 'SCHEDULED';
  createdBy: string;
  /** @format date-time */
  created: string;
}

export interface MrzScannedDataHistory {
  id: string;
  kycRecord: KycRecord;
  kycRecordId: string;
  fromFilename: string | null;
  firstName: string | null;
  lastName: string | null;
  documentCode: string | null;
  issuingState: string | null;
  documentNumber: string | null;
  documentNumberCheckDigit: string | null;
  nationality: string | null;
  birthDate: string | null;
  birthDateCheckDigit: string | null;
  sex: string | null;
  expirationDate: string | null;
  expirationDateCheckDigit: string | null;
  personalNumber: string | null;
  personalNumberCheckDigit: string | null;
  compositeCheckDigit: string | null;
  format: string | null;
  valid: boolean;
  rawMrz: string;
  /** @format date-time */
  created: string;
  /** @format date-time */
  updated: string;
}

export enum OIDIdentificationStatus {
  Open = 'Open',
  Requested = 'Requested',
  Pending = 'Pending',
  InReview = 'InReview',
  Approved = 'Approved',
  Rejected = 'Rejected',
  ADVANON_CHECKED = 'ADVANON_CHECKED',
}

export interface OIDIdentificationHistory {
  status: OIDIdentificationStatus;
  id: string;
  kycRecord: KycRecord;
  kycRecordId: string;
  token?: string;
  marking?: string;
  checks?: object;
  adminName: string;
  /** @format date-time */
  created: string;
  /** @format date-time */
  updatedAt: string;
}

export interface Country {
  capital: string;
  continent: string;
  currency: string;
  emoji: string;
  emojiU: string;
  languages: string[];
  name: string;
  native: string;
  phone: string;
  shortName: string;
}

export interface AddressEntity {
  country: Country | null;
  street: string | null;
  zipcode: string | null;
  city: string | null;
  streetNr: string | null;
  confirmedInvalid: boolean;
}

export interface BeneficialOwner {
  nationality: Country | null;
  id: number;
  kycRecordId: string;
  kycRecord: KycRecord;
  firstName: string | null;
  lastName: string | null;
  title: 'MR' | 'MS' | 'MX' | null;
  dateOfBirth: string | null;
  address: AddressEntity;
  /** @format date-time */
  addressVerifiedAt: string | null;
  beneficialOwnerDataUserConfirmation: boolean;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
}

export enum Title {
  MR = 'MR',
  MS = 'MS',
  MX = 'MX',
}

export interface ContactPerson {
  title: Title | null;
  nationality: Country | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  dateOfBirth: string | null;
  address: AddressEntity;
  /** @format date-time */
  addressVerifiedAt: string | null;
  phoneNumber: string | null;
  isBeneficialOwner: boolean;
  individualSigningAuthority: boolean;
  contactPersonDataUserConfirmation: boolean;
}

export interface KycRecord {
  beneficialOwnerStatus: BeneficialOwnerStatus | null;
  category?: InvestorCategory | null;
  id: string;
  type: string;
  migrated: boolean;
  tenantId: string;
  tenant: TenantEntity;
  pepCheckHistory: PEPCheckHistory[];
  bankVerificationHistory: BankVerificationHistory[];
  adminApprovalHistory: AdminApprovalHistory[];
  onlineIdentificationHistory: OnlineIdentificationHistory[];
  identificationMeetingHistory: IdentificationMeetingHistory[];
  mrzScannedDataHistory: MrzScannedDataHistory[];
  oidIdentificationHistory: OIDIdentificationHistory[];
  beneficialOwners?: BeneficialOwner[];
  contactPerson: ContactPerson;
  continued: boolean | null;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  mrzCode?: string | null;
}

export interface PEPCheckHistory {
  status: PEPCheckStatus;
  id: string;
  kycRecord: KycRecord;
  kycRecordId: string;
  crifCheck?: boolean;
  fileStored?: boolean;
  adminName: string;
  /** @format date-time */
  created: string;
}

export interface PrivateKycRecord {
  beneficialOwnerStatus: BeneficialOwnerStatus | null;
  category?: InvestorCategory | null;
  politicalExposure: YesNo | null;
  privateAnnualIncome: PrivateAnnualIncome | null;
  employmentStatus: EmploymentStatus | null;
  educationLevel: EducationLevel | null;
  privatePlannedInvestment: PrivateInvestingPlan | null;
  privateNetWorth: PrivateNetWorth | null;
  personalDataUserConfirmation: boolean;
  employmentJobTitle: string | null;
  employmentEmployer: string | null;
  moneyOrigination: string | null;
  id: string;
  type: string;
  migrated: boolean;
  tenantId: string;
  tenant: TenantEntity;
  pepCheckHistory: PEPCheckHistory[];
  bankVerificationHistory: BankVerificationHistory[];
  adminApprovalHistory: AdminApprovalHistory[];
  onlineIdentificationHistory: OnlineIdentificationHistory[];
  identificationMeetingHistory: IdentificationMeetingHistory[];
  mrzScannedDataHistory: MrzScannedDataHistory[];
  oidIdentificationHistory: OIDIdentificationHistory[];
  beneficialOwners?: BeneficialOwner[];
  contactPerson: ContactPerson;
  continued: boolean | null;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  mrzCode?: string | null;
}

export enum BusinessInvestingPlan {
  LESS100K = 'LESS_100K',
  Value100K250K = '100K_250K',
  MORE250K = 'MORE_250K',
}

export enum BusinessAnnualIncome {
  LESS100K = 'LESS_100K',
  Value100K1M = '100K_1M',
  MORE1M = 'MORE_1M',
}

export enum BusinessNetWorth {
  LESS100K = 'LESS_100K',
  Value100K1M = '100K_1M',
  MORE1M = 'MORE_1M',
}

export interface BusinessKycRecord {
  beneficialOwnerStatus: BeneficialOwnerStatus | null;
  category?: InvestorCategory | null;
  politicalExposure: YesNo | null;
  businessPlannedInvestment: BusinessInvestingPlan | null;
  businessAnnualIncome: BusinessAnnualIncome | null;
  businessNetWorth: BusinessNetWorth | null;
  companyName: string | null;
  companyUID: string | null;
  industry: string | null;
  legalStructure: string | null;
  address: AddressEntity;
  /** @format date-time */
  addressVerifiedAt: string | null;
  moneyOrigination: string | null;
  id: string;
  type: string;
  migrated: boolean;
  tenantId: string;
  tenant: TenantEntity;
  pepCheckHistory: PEPCheckHistory[];
  bankVerificationHistory: BankVerificationHistory[];
  adminApprovalHistory: AdminApprovalHistory[];
  onlineIdentificationHistory: OnlineIdentificationHistory[];
  identificationMeetingHistory: IdentificationMeetingHistory[];
  mrzScannedDataHistory: MrzScannedDataHistory[];
  oidIdentificationHistory: OIDIdentificationHistory[];
  beneficialOwners?: BeneficialOwner[];
  contactPerson: ContactPerson;
  continued: boolean | null;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  mrzCode?: string | null;
}

export type IdentificationMeetingDto = object;

export interface AddressDto {
  country?: Country | null;
  city?: string | null;
  street?: string | null;
  streetNr?: string | null;
  zipcode?: string | null;
  confirmedInvalid?: boolean;
}

export interface PEPCheckHistoryDto {
  status: 'NO_HIT' | 'HIT' | 'OPEN' | 'UNKNOWN' | 'NO_HIT_OLD' | 'HIT_BUT_OK' | 'ADVANON_CHECKED';
  fileStored?: boolean;
  crifCheck?: boolean;
  adminName: string;
  /** @format date-time */
  created?: string;
}

export interface OIDIdentificationHistoryDto {
  status:
    | 'Open'
    | 'Requested'
    | 'Pending'
    | 'InReview'
    | 'Approved'
    | 'Rejected'
    | 'ADVANON_CHECKED';
  adminName?: string;
  /** @format date-time */
  created?: string;
}

export interface ContactPersonDto {
  nationality?: Country | null;
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  title?: 'MR' | 'MS' | 'MX' | null;
  dateOfBirth?: string | null;
  address?: AddressDto;
  phoneNumber?: string | null;
  isBeneficialOwner?: boolean;
  individualSigningAuthority?: boolean;
  contactPersonDataUserConfirmation?: boolean;
}

export interface BeneficialOwnerDto {
  nationality?: Country | null;
  id?: number;
  firstName?: string | null;
  lastName?: string | null;
  title?: 'MR' | 'MS' | 'MX' | null;
  dateOfBirth?: string | null;
  address?: AddressDto;
  beneficialOwnerDataUserConfirmation?: boolean;
}

export interface CreateBusinessKycRecordDto {
  id: string;
  companyName?: string | null;
  companyUID?: string | null;
  industry?: string | null;
  legalStructure?: string | null;
  address?: AddressDto;
  politicalExposure?: 'yes' | 'no' | null;
  businessPlannedInvestment?: 'LESS_100K' | '100K_250K' | 'MORE_250K' | null;
  businessAnnualIncome?: 'LESS_100K' | '100K_1M' | 'MORE_1M' | null;
  businessNetWorth?: 'LESS_100K' | '100K_1M' | 'MORE_1M' | null;
  moneyOrigination?: string | null;
  migrated?: boolean;
  continued?: boolean;
  pepCheckHistory?: PEPCheckHistoryDto[];
  oidIdentificationHistory?: OIDIdentificationHistoryDto[];
  category?:
    | 'FAMILY_OFFICE'
    | 'UHNWI'
    | 'HNWI'
    | 'RETAIL_INVESTOR'
    | 'OTHER_PRIVATE'
    | 'ASSET_MANAGER'
    | 'MUTUAL_FUND'
    | 'PENSION_FUND'
    | 'HEDGE_FUND'
    | 'OTHER_FUND'
    | 'BANK'
    | 'OTHER_FINANCIAL_INSTITUTION'
    | 'TRUST'
    | 'OTHER'
    | 'SENIOR_INVESTOR'
    | 'JUNIOR_INVESTOR'
    | null;
  /** @format date-time */
  createdAt?: string;
  contactPerson?: ContactPersonDto;
  beneficialOwners?: BeneficialOwnerDto[];
  beneficialOwnerStatus?:
    | 'HOLDING_MORE_THAN_25_SHARES'
    | 'CONTROLLING_IN_OTHER_WAYS'
    | 'MANAGING_DIRECTORS'
    | null;
}

export interface CreatePrivateKycRecordDto {
  id: string;
  personalDataUserConfirmation?: boolean;
  politicalExposure?: 'yes' | 'no' | null;
  privateAnnualIncome?: 'LESS_100K' | '100K_200K' | '200K_500K' | 'MORE_500K' | null;
  educationLevel?:
    | 'BASIC_EDUCATION'
    | 'VOCATIONAL'
    | 'SECONDARY_EDUCATION'
    | 'HIGHER_VOCATIONAL'
    | 'BACHELOR'
    | 'MASTER_DR'
    | null;
  employmentStatus?:
    | 'EMPLOYED'
    | 'INDEPENDENT'
    | 'STUDENT'
    | 'PENSIONER'
    | 'IV_PENSIONER'
    | 'UNEMPLOYED'
    | null;
  employmentJobTitle?: string | null;
  employmentEmployer?: string | null;
  privatePlannedInvestment?: 'LESS_100K' | '100K_250K' | 'MORE_250K' | null;
  privateNetWorth?: 'LESS_100K' | '100K_500K' | '500K_2M' | 'MORE_2M' | null;
  moneyOrigination?: string | null;
  migrated?: boolean;
  continued?: boolean;
  pepCheckHistory?: PEPCheckHistoryDto[];
  oidIdentificationHistory?: OIDIdentificationHistoryDto[];
  category?:
    | 'FAMILY_OFFICE'
    | 'UHNWI'
    | 'HNWI'
    | 'RETAIL_INVESTOR'
    | 'OTHER_PRIVATE'
    | 'ASSET_MANAGER'
    | 'MUTUAL_FUND'
    | 'PENSION_FUND'
    | 'HEDGE_FUND'
    | 'OTHER_FUND'
    | 'BANK'
    | 'OTHER_FINANCIAL_INSTITUTION'
    | 'TRUST'
    | 'OTHER'
    | 'SENIOR_INVESTOR'
    | 'JUNIOR_INVESTOR'
    | null;
  /** @format date-time */
  createdAt?: string;
  contactPerson?: ContactPersonDto;
  beneficialOwners?: BeneficialOwnerDto[];
  beneficialOwnerStatus?:
    | 'HOLDING_MORE_THAN_25_SHARES'
    | 'CONTROLLING_IN_OTHER_WAYS'
    | 'MANAGING_DIRECTORS'
    | null;
}

export interface UpdateBusinessKycRecordDto {
  companyName?: string | null;
  companyUID?: string | null;
  industry?: string | null;
  legalStructure?: string | null;
  address?: AddressDto;
  politicalExposure?: 'yes' | 'no' | null;
  businessPlannedInvestment?: 'LESS_100K' | '100K_250K' | 'MORE_250K' | null;
  businessAnnualIncome?: 'LESS_100K' | '100K_1M' | 'MORE_1M' | null;
  businessNetWorth?: 'LESS_100K' | '100K_1M' | 'MORE_1M' | null;
  moneyOrigination?: string | null;
  migrated?: boolean;
  continued?: boolean;
  pepCheckHistory?: PEPCheckHistoryDto[];
  oidIdentificationHistory?: OIDIdentificationHistoryDto[];
  category?:
    | 'FAMILY_OFFICE'
    | 'UHNWI'
    | 'HNWI'
    | 'RETAIL_INVESTOR'
    | 'OTHER_PRIVATE'
    | 'ASSET_MANAGER'
    | 'MUTUAL_FUND'
    | 'PENSION_FUND'
    | 'HEDGE_FUND'
    | 'OTHER_FUND'
    | 'BANK'
    | 'OTHER_FINANCIAL_INSTITUTION'
    | 'TRUST'
    | 'OTHER'
    | 'SENIOR_INVESTOR'
    | 'JUNIOR_INVESTOR'
    | null;
  /** @format date-time */
  createdAt?: string;
  contactPerson?: ContactPersonDto;
  beneficialOwners?: BeneficialOwnerDto[];
  beneficialOwnerStatus?:
    | 'HOLDING_MORE_THAN_25_SHARES'
    | 'CONTROLLING_IN_OTHER_WAYS'
    | 'MANAGING_DIRECTORS'
    | null;
}

export interface UpdatePrivateKycRecordDto {
  personalDataUserConfirmation?: boolean;
  politicalExposure?: 'yes' | 'no' | null;
  privateAnnualIncome?: 'LESS_100K' | '100K_200K' | '200K_500K' | 'MORE_500K' | null;
  educationLevel?:
    | 'BASIC_EDUCATION'
    | 'VOCATIONAL'
    | 'SECONDARY_EDUCATION'
    | 'HIGHER_VOCATIONAL'
    | 'BACHELOR'
    | 'MASTER_DR'
    | null;
  employmentStatus?:
    | 'EMPLOYED'
    | 'INDEPENDENT'
    | 'STUDENT'
    | 'PENSIONER'
    | 'IV_PENSIONER'
    | 'UNEMPLOYED'
    | null;
  employmentJobTitle?: string | null;
  employmentEmployer?: string | null;
  privatePlannedInvestment?: 'LESS_100K' | '100K_250K' | 'MORE_250K' | null;
  privateNetWorth?: 'LESS_100K' | '100K_500K' | '500K_2M' | 'MORE_2M' | null;
  moneyOrigination?: string | null;
  migrated?: boolean;
  continued?: boolean;
  pepCheckHistory?: PEPCheckHistoryDto[];
  oidIdentificationHistory?: OIDIdentificationHistoryDto[];
  category?:
    | 'FAMILY_OFFICE'
    | 'UHNWI'
    | 'HNWI'
    | 'RETAIL_INVESTOR'
    | 'OTHER_PRIVATE'
    | 'ASSET_MANAGER'
    | 'MUTUAL_FUND'
    | 'PENSION_FUND'
    | 'HEDGE_FUND'
    | 'OTHER_FUND'
    | 'BANK'
    | 'OTHER_FINANCIAL_INSTITUTION'
    | 'TRUST'
    | 'OTHER'
    | 'SENIOR_INVESTOR'
    | 'JUNIOR_INVESTOR'
    | null;
  /** @format date-time */
  createdAt?: string;
  contactPerson?: ContactPersonDto;
  beneficialOwners?: BeneficialOwnerDto[];
  beneficialOwnerStatus?:
    | 'HOLDING_MORE_THAN_25_SHARES'
    | 'CONTROLLING_IN_OTHER_WAYS'
    | 'MANAGING_DIRECTORS'
    | null;
}

export interface PEPCheckDto {
  status: 'NO_HIT' | 'HIT' | 'OPEN' | 'UNKNOWN' | 'NO_HIT_OLD' | 'HIT_BUT_OK' | 'ADVANON_CHECKED';
  fileStored?: boolean;
  crifCheck?: boolean;
}

export interface CrifIdentifier {
  identifier?: string;
}

export interface CrifAddressDto {
  street?: string;
  houseNumber?: string;
  apartment?: string;
  zip?: string;
  city?: string;
  regionCode?: string;
  subRegionCode?: string;
  country?: string;
}

export interface CandidateDto {
  identifier: string;
  address?: CrifAddressDto;
  firstName?: string;
  lastName?: string;
  middleName?: string;
  birthDate?: string;
  candidateRank?: number;
}

export interface CandidatesDto {
  candidates?: CandidateDto[];
}

export interface DepositDataDto {
  iban: string;
  accountHolder: string;
  address: string;
}

export interface BankVerificationDto {
  status: 'OPEN' | 'APPROVED' | 'DECLINED' | 'ADVANON_CHECKED' | 'NOT_NEEDED';
}

export interface AdminApprovalDto {
  status:
    | 'TODO'
    | 'APPROVED'
    | 'PENDING'
    | 'REJECTED'
    | 'EXPIRED'
    | 'INCOMPLETE'
    | 'ADVANON_CHECKED';
}

export interface OnlineIdentificationDto {
  status: 'OPEN' | 'PENDING' | 'IN_REVIEW' | 'APPROVED' | 'REJECTED';
  comment?: string | null;
}

export interface CreateTaskGroupDto {
  type:
    | 'PRIVATE_IDENTIFICATION'
    | 'PRIVATE_BENEFICIAL_OWNER'
    | 'PRIVATE_PROFILE_QUESTIONNAIRE'
    | 'PRIVATE_BANK_VERIFICATION'
    | 'COMPANY_DETAILS'
    | 'COMPANY_CONTACT_PERSON'
    | 'COMPANY_BENEFICIAL_OWNER'
    | 'COMPANY_PROFILE_QUESTIONNAIRE'
    | 'COMPANY_BANK_VERIFICATION';
  tasks: (
    | 'TASK_LIST'
    | 'OID_FLOW'
    | 'COLLECTING_OWNER_ID'
    | 'CALENDAR_BOOKING'
    | 'COLLECTING_PERSONAL_INFORMATION_NAME'
    | 'COLLECTING_PERSONAL_INFORMATION_NATIONALITY'
    | 'COLLECTING_PERSONAL_INFORMATION_DOB'
    | 'COLLECTING_PERSONAL_INFORMATION_ADDRESS'
    | 'COLLECTING_PHONE_NUMBER'
    | 'VERIFYING_PHONE_NUMBER'
    | 'REVIEWING_PERSONAL_INFORMATION'
    | 'COLLECTING_PRIVATE_BENEFICIAL_OWNER_NAME'
    | 'COLLECTING_PRIVATE_BENEFICIAL_OWNER_NATIONALITY'
    | 'COLLECTING_PRIVATE_BENEFICIAL_OWNER_ADDRESS'
    | 'COLLECTING_PRIVATE_BENEFICIAL_OWNER_DOB'
    | 'REVIEWING_PRIVATE_BENEFICIAL_OWNER'
    | 'COLLECTING_PRIVATE_BENEFICIAL_OWNER_ID'
    | 'COLLECTING_PRIVATE_BENEFICIAL_OWNER_LIST'
    | 'COLLECTING_POLITICAL_EXPOSURE'
    | 'COLLECTING_PERSONAL_INFORMATION_EDUCATION'
    | 'COLLECTING_PERSONAL_INFORMATION_EMPLOYMENT_STATUS'
    | 'COLLECTING_PERSONAL_INFORMATION_EMPLOYMENT_EMPLOYER'
    | 'COLLECTING_INVESTMENT_STRATEGY_PLANNED_INVESTMENT'
    | 'COLLECTING_INVESTMENT_STRATEGY_ANNUAL_INCOME'
    | 'COLLECTING_INVESTMENT_STRATEGY_NET_WORTH'
    | 'COLLECTING_INVESTMENT_STRATEGY_MONEY_ORIGINATION'
    | 'PRIVATE_BANK_VERIFICATION_DETAILS'
    | 'COLLECTING_COMPANY_DETAILS_NAME_UID'
    | 'COLLECTING_COMPANY_DETAILS_INDUSTRY'
    | 'COLLECTING_COMPANY_DETAILS_LEGAL_STRUCTURE'
    | 'COLLECTING_COMPANY_DETAILS_ADDRESS'
    | 'COLLECTING_COMPANY_CONTACT_PERSON_NAME'
    | 'COLLECTING_COMPANY_CONTACT_PERSON_NATIONALITY'
    | 'COLLECTING_COMPANY_CONTACT_PERSON_DOB'
    | 'COLLECTING_COMPANY_CONTACT_PERSON_ADDRESS'
    | 'COLLECTING_COMPANY_CONTACT_PERSON_PHONE_NUMBER'
    | 'VERIFYING_COMPANY_CONTACT_PERSON_PHONE_NUMBER'
    | 'COLLECTING_COMPANY_CONTACT_PERSON_SIGNING_AUTHORITY'
    | 'COLLECTING_COMPANY_CONTACT_PERSON_POA'
    | 'REVIEWING_COMPANY_CONTACT_PERSON'
    | 'COLLECTING_COMPANY_BENEFICIAL_OWNER_NAME'
    | 'COLLECTING_COMPANY_BENEFICIAL_OWNER_NATIONALITY'
    | 'COLLECTING_COMPANY_BENEFICIAL_OWNER_ADDRESS'
    | 'COLLECTING_COMPANY_BENEFICIAL_OWNER_DOB'
    | 'REVIEWING_COMPANY_BENEFICIAL_OWNER'
    | 'COLLECTING_COMPANY_BENEFICIAL_OWNER_ID'
    | 'COLLECTING_COMPANY_BENEFICIAL_OWNER_LIST'
    | 'COLLECTING_COMPANY_QUESTIONNAIRE_POLITICAL_EXPOSURE'
    | 'COLLECTING_COMPANY_QUESTIONNAIRE_PLANNED_INVESTMENT'
    | 'COLLECTING_COMPANY_QUESTIONNAIRE_ANNUAL_INCOME'
    | 'COLLECTING_COMPANY_QUESTIONNAIRE_NET_WORTH'
    | 'COLLECTING_COMPANY_QUESTIONNAIRE_MONEY_ORIGINATION'
    | 'COLLECTING_COMPANY_QUESTIONNAIRE_STATEMENT'
    | 'COMPANY_BANK_VERIFICATION_DETAILS'
  )[];
  skip: boolean;
}

export interface TaskGroup {
  id: number;
  tenantId: string;
  tenant: TenantEntity;
  type:
    | 'PRIVATE_IDENTIFICATION'
    | 'PRIVATE_BENEFICIAL_OWNER'
    | 'PRIVATE_PROFILE_QUESTIONNAIRE'
    | 'PRIVATE_BANK_VERIFICATION'
    | 'COMPANY_DETAILS'
    | 'COMPANY_CONTACT_PERSON'
    | 'COMPANY_BENEFICIAL_OWNER'
    | 'COMPANY_PROFILE_QUESTIONNAIRE'
    | 'COMPANY_BANK_VERIFICATION';
  tasks: (
    | 'TASK_LIST'
    | 'OID_FLOW'
    | 'COLLECTING_OWNER_ID'
    | 'CALENDAR_BOOKING'
    | 'COLLECTING_PERSONAL_INFORMATION_NAME'
    | 'COLLECTING_PERSONAL_INFORMATION_NATIONALITY'
    | 'COLLECTING_PERSONAL_INFORMATION_DOB'
    | 'COLLECTING_PERSONAL_INFORMATION_ADDRESS'
    | 'COLLECTING_PHONE_NUMBER'
    | 'VERIFYING_PHONE_NUMBER'
    | 'REVIEWING_PERSONAL_INFORMATION'
    | 'COLLECTING_PRIVATE_BENEFICIAL_OWNER_NAME'
    | 'COLLECTING_PRIVATE_BENEFICIAL_OWNER_NATIONALITY'
    | 'COLLECTING_PRIVATE_BENEFICIAL_OWNER_ADDRESS'
    | 'COLLECTING_PRIVATE_BENEFICIAL_OWNER_DOB'
    | 'REVIEWING_PRIVATE_BENEFICIAL_OWNER'
    | 'COLLECTING_PRIVATE_BENEFICIAL_OWNER_ID'
    | 'COLLECTING_PRIVATE_BENEFICIAL_OWNER_LIST'
    | 'COLLECTING_POLITICAL_EXPOSURE'
    | 'COLLECTING_PERSONAL_INFORMATION_EDUCATION'
    | 'COLLECTING_PERSONAL_INFORMATION_EMPLOYMENT_STATUS'
    | 'COLLECTING_PERSONAL_INFORMATION_EMPLOYMENT_EMPLOYER'
    | 'COLLECTING_INVESTMENT_STRATEGY_PLANNED_INVESTMENT'
    | 'COLLECTING_INVESTMENT_STRATEGY_ANNUAL_INCOME'
    | 'COLLECTING_INVESTMENT_STRATEGY_NET_WORTH'
    | 'COLLECTING_INVESTMENT_STRATEGY_MONEY_ORIGINATION'
    | 'PRIVATE_BANK_VERIFICATION_DETAILS'
    | 'COLLECTING_COMPANY_DETAILS_NAME_UID'
    | 'COLLECTING_COMPANY_DETAILS_INDUSTRY'
    | 'COLLECTING_COMPANY_DETAILS_LEGAL_STRUCTURE'
    | 'COLLECTING_COMPANY_DETAILS_ADDRESS'
    | 'COLLECTING_COMPANY_CONTACT_PERSON_NAME'
    | 'COLLECTING_COMPANY_CONTACT_PERSON_NATIONALITY'
    | 'COLLECTING_COMPANY_CONTACT_PERSON_DOB'
    | 'COLLECTING_COMPANY_CONTACT_PERSON_ADDRESS'
    | 'COLLECTING_COMPANY_CONTACT_PERSON_PHONE_NUMBER'
    | 'VERIFYING_COMPANY_CONTACT_PERSON_PHONE_NUMBER'
    | 'COLLECTING_COMPANY_CONTACT_PERSON_SIGNING_AUTHORITY'
    | 'COLLECTING_COMPANY_CONTACT_PERSON_POA'
    | 'REVIEWING_COMPANY_CONTACT_PERSON'
    | 'COLLECTING_COMPANY_BENEFICIAL_OWNER_NAME'
    | 'COLLECTING_COMPANY_BENEFICIAL_OWNER_NATIONALITY'
    | 'COLLECTING_COMPANY_BENEFICIAL_OWNER_ADDRESS'
    | 'COLLECTING_COMPANY_BENEFICIAL_OWNER_DOB'
    | 'REVIEWING_COMPANY_BENEFICIAL_OWNER'
    | 'COLLECTING_COMPANY_BENEFICIAL_OWNER_ID'
    | 'COLLECTING_COMPANY_BENEFICIAL_OWNER_LIST'
    | 'COLLECTING_COMPANY_QUESTIONNAIRE_POLITICAL_EXPOSURE'
    | 'COLLECTING_COMPANY_QUESTIONNAIRE_PLANNED_INVESTMENT'
    | 'COLLECTING_COMPANY_QUESTIONNAIRE_ANNUAL_INCOME'
    | 'COLLECTING_COMPANY_QUESTIONNAIRE_NET_WORTH'
    | 'COLLECTING_COMPANY_QUESTIONNAIRE_MONEY_ORIGINATION'
    | 'COLLECTING_COMPANY_QUESTIONNAIRE_STATEMENT'
    | 'COMPANY_BANK_VERIFICATION_DETAILS'
  )[];
  skip: boolean;
}

export interface CreateTaskDto {
  type: string;
  description: string;
  template: string;
}

export interface Task {
  id: number;
  tenantId: string;
  tenant: TenantEntity;
  type:
    | 'TASK_LIST'
    | 'OID_FLOW'
    | 'COLLECTING_OWNER_ID'
    | 'CALENDAR_BOOKING'
    | 'COLLECTING_PERSONAL_INFORMATION_NAME'
    | 'COLLECTING_PERSONAL_INFORMATION_NATIONALITY'
    | 'COLLECTING_PERSONAL_INFORMATION_DOB'
    | 'COLLECTING_PERSONAL_INFORMATION_ADDRESS'
    | 'COLLECTING_PHONE_NUMBER'
    | 'VERIFYING_PHONE_NUMBER'
    | 'REVIEWING_PERSONAL_INFORMATION'
    | 'COLLECTING_PRIVATE_BENEFICIAL_OWNER_NAME'
    | 'COLLECTING_PRIVATE_BENEFICIAL_OWNER_NATIONALITY'
    | 'COLLECTING_PRIVATE_BENEFICIAL_OWNER_ADDRESS'
    | 'COLLECTING_PRIVATE_BENEFICIAL_OWNER_DOB'
    | 'REVIEWING_PRIVATE_BENEFICIAL_OWNER'
    | 'COLLECTING_PRIVATE_BENEFICIAL_OWNER_ID'
    | 'COLLECTING_PRIVATE_BENEFICIAL_OWNER_LIST'
    | 'COLLECTING_POLITICAL_EXPOSURE'
    | 'COLLECTING_PERSONAL_INFORMATION_EDUCATION'
    | 'COLLECTING_PERSONAL_INFORMATION_EMPLOYMENT_STATUS'
    | 'COLLECTING_PERSONAL_INFORMATION_EMPLOYMENT_EMPLOYER'
    | 'COLLECTING_INVESTMENT_STRATEGY_PLANNED_INVESTMENT'
    | 'COLLECTING_INVESTMENT_STRATEGY_ANNUAL_INCOME'
    | 'COLLECTING_INVESTMENT_STRATEGY_NET_WORTH'
    | 'COLLECTING_INVESTMENT_STRATEGY_MONEY_ORIGINATION'
    | 'PRIVATE_BANK_VERIFICATION_DETAILS'
    | 'COLLECTING_COMPANY_DETAILS_NAME_UID'
    | 'COLLECTING_COMPANY_DETAILS_INDUSTRY'
    | 'COLLECTING_COMPANY_DETAILS_LEGAL_STRUCTURE'
    | 'COLLECTING_COMPANY_DETAILS_ADDRESS'
    | 'COLLECTING_COMPANY_CONTACT_PERSON_NAME'
    | 'COLLECTING_COMPANY_CONTACT_PERSON_NATIONALITY'
    | 'COLLECTING_COMPANY_CONTACT_PERSON_DOB'
    | 'COLLECTING_COMPANY_CONTACT_PERSON_ADDRESS'
    | 'COLLECTING_COMPANY_CONTACT_PERSON_PHONE_NUMBER'
    | 'VERIFYING_COMPANY_CONTACT_PERSON_PHONE_NUMBER'
    | 'COLLECTING_COMPANY_CONTACT_PERSON_SIGNING_AUTHORITY'
    | 'COLLECTING_COMPANY_CONTACT_PERSON_POA'
    | 'REVIEWING_COMPANY_CONTACT_PERSON'
    | 'COLLECTING_COMPANY_BENEFICIAL_OWNER_NAME'
    | 'COLLECTING_COMPANY_BENEFICIAL_OWNER_NATIONALITY'
    | 'COLLECTING_COMPANY_BENEFICIAL_OWNER_ADDRESS'
    | 'COLLECTING_COMPANY_BENEFICIAL_OWNER_DOB'
    | 'REVIEWING_COMPANY_BENEFICIAL_OWNER'
    | 'COLLECTING_COMPANY_BENEFICIAL_OWNER_ID'
    | 'COLLECTING_COMPANY_BENEFICIAL_OWNER_LIST'
    | 'COLLECTING_COMPANY_QUESTIONNAIRE_POLITICAL_EXPOSURE'
    | 'COLLECTING_COMPANY_QUESTIONNAIRE_PLANNED_INVESTMENT'
    | 'COLLECTING_COMPANY_QUESTIONNAIRE_ANNUAL_INCOME'
    | 'COLLECTING_COMPANY_QUESTIONNAIRE_NET_WORTH'
    | 'COLLECTING_COMPANY_QUESTIONNAIRE_MONEY_ORIGINATION'
    | 'COLLECTING_COMPANY_QUESTIONNAIRE_STATEMENT'
    | 'COMPANY_BANK_VERIFICATION_DETAILS';
  template: string;
  description: string;
  requiresMFA: boolean;
}

export interface TenantDto {
  id: string;
}

export interface TenantCreateDTO {
  id: string;
  authKey: string;
}

export interface CreateTaskProfileDto {
  category: 'Private' | 'Business';
  type: string;
  description: string;
  taskGroups: (
    | 'PRIVATE_IDENTIFICATION'
    | 'PRIVATE_BENEFICIAL_OWNER'
    | 'PRIVATE_PROFILE_QUESTIONNAIRE'
    | 'PRIVATE_BANK_VERIFICATION'
    | 'COMPANY_DETAILS'
    | 'COMPANY_CONTACT_PERSON'
    | 'COMPANY_BENEFICIAL_OWNER'
    | 'COMPANY_PROFILE_QUESTIONNAIRE'
    | 'COMPANY_BANK_VERIFICATION'
  )[];
}

export interface TaskProfile {
  id: number;
  tenantId: string;
  tenant: TenantEntity;
  category: 'Private' | 'Business';
  type: string;
  taskGroups: (
    | 'PRIVATE_IDENTIFICATION'
    | 'PRIVATE_BENEFICIAL_OWNER'
    | 'PRIVATE_PROFILE_QUESTIONNAIRE'
    | 'PRIVATE_BANK_VERIFICATION'
    | 'COMPANY_DETAILS'
    | 'COMPANY_CONTACT_PERSON'
    | 'COMPANY_BENEFICIAL_OWNER'
    | 'COMPANY_PROFILE_QUESTIONNAIRE'
    | 'COMPANY_BANK_VERIFICATION'
  )[];
  description: string | null;
}

export interface ProblemDocument {
  status: number;
  title: string;
  detail?: string;
  instance?: string;
  type?: string;
}

export interface KycRecordControllerFindAllParams {
  type?: string;
  pageSize?: number;
  pageIndex?: number;
  generalFindString?: string;
}

export interface KycRecordControllerRequestOidIdentificationParams {
  lang: string;
  id: string;
}

export interface TaskProfileControllerFindAllParams {
  type?: string;
  pageSize?: number;
  pageIndex?: number;
}

export interface TaskRecordControllerFindKycProfileRecordParams {
  kycRecordId: string;
  taskProfileType: string;
  text: boolean;
}

import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  HeadersDefaults,
  ResponseType,
} from 'axios';

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams
  extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown>
  extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({
    securityWorker,
    secure,
    format,
    ...axiosConfig
  }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || '' });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(
    params1: AxiosRequestConfig,
    params2?: AxiosRequestConfig,
  ): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method &&
          this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) ||
          {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === 'object' && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] = property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (type === ContentType.FormData && body && body !== null && typeof body === 'object') {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== 'string') {
      body = JSON.stringify(body);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}

/**
 * @title KYC Admin API
 * @version 1.0
 * @contact
 *
 * KYC API.
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  api = {
    /**
     * No description
     *
     * @tags admin/kyc-record
     * @name KycRecordControllerCreateOneBusiness
     * @request POST:/api/admin/kyc-record/business
     * @secure
     */
    kycRecordControllerCreateOneBusiness: (
      data: CreateBusinessKycRecordDto,
      params: RequestParams = {},
    ) =>
      this.request<BusinessKycRecord, any>({
        path: `/api/admin/kyc-record/business`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin/kyc-record
     * @name KycRecordControllerCreateOnePrivate
     * @request POST:/api/admin/kyc-record/private
     * @secure
     */
    kycRecordControllerCreateOnePrivate: (
      data: CreatePrivateKycRecordDto,
      params: RequestParams = {},
    ) =>
      this.request<PrivateKycRecord, any>({
        path: `/api/admin/kyc-record/private`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin/kyc-record
     * @name KycRecordControllerFindAll
     * @request GET:/api/admin/kyc-record
     * @secure
     */
    kycRecordControllerFindAll: (
      query: KycRecordControllerFindAllParams,
      params: RequestParams = {},
    ) =>
      this.request<
        {
          items: KycRecord[];
          meta: {
            pageSize: number;
            pageIndex: number;
            totalItems: number;
          };
        },
        any
      >({
        path: `/api/admin/kyc-record`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin/kyc-record
     * @name KycRecordControllerFineOne
     * @request GET:/api/admin/kyc-record/{id}
     * @secure
     */
    kycRecordControllerFineOne: (id: string, params: RequestParams = {}) =>
      this.request<KycRecord, any>({
        path: `/api/admin/kyc-record/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin/kyc-record
     * @name KycRecordControllerUpdateOneBusiness
     * @request PATCH:/api/admin/kyc-record/business/{id}
     * @secure
     */
    kycRecordControllerUpdateOneBusiness: (
      id: string,
      data: UpdateBusinessKycRecordDto,
      params: RequestParams = {},
    ) =>
      this.request<BusinessKycRecord, any>({
        path: `/api/admin/kyc-record/business/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin/kyc-record
     * @name KycRecordControllerUpdateOnePrivate
     * @request PATCH:/api/admin/kyc-record/private/{id}
     * @secure
     */
    kycRecordControllerUpdateOnePrivate: (
      id: string,
      data: UpdatePrivateKycRecordDto,
      params: RequestParams = {},
    ) =>
      this.request<PrivateKycRecord, any>({
        path: `/api/admin/kyc-record/private/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin/kyc-record
     * @name KycRecordControllerFindAllPepChecks
     * @request GET:/api/admin/kyc-record/{id}/pepChecks
     * @secure
     */
    kycRecordControllerFindAllPepChecks: (id: string, params: RequestParams = {}) =>
      this.request<PEPCheckHistory[], any>({
        path: `/api/admin/kyc-record/${id}/pepChecks`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin/kyc-record
     * @name KycRecordControllerCreatePepCheck
     * @request POST:/api/admin/kyc-record/{id}/pepChecks
     * @secure
     */
    kycRecordControllerCreatePepCheck: (
      id: string,
      data: PEPCheckDto,
      params: RequestParams = {},
    ) =>
      this.request<PEPCheckHistory, any>({
        path: `/api/admin/kyc-record/${id}/pepChecks`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin/kyc-record
     * @name KycRecordControllerRequestPepCheck
     * @request POST:/api/admin/kyc-record/{id}/pepChecks/request
     * @secure
     */
    kycRecordControllerRequestPepCheck: (
      id: string,
      data: CrifIdentifier,
      params: RequestParams = {},
    ) =>
      this.request<CandidatesDto, any>({
        path: `/api/admin/kyc-record/${id}/pepChecks/request`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin/kyc-record
     * @name KycRecordControllerRequestOidIdentification
     * @request POST:/api/admin/kyc-record/{id}/swisscom/request
     * @secure
     */
    kycRecordControllerRequestOidIdentification: (
      { id, ...query }: KycRecordControllerRequestOidIdentificationParams,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/admin/kyc-record/${id}/swisscom/request`,
        method: 'POST',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin/kyc-record
     * @name KycRecordControllerGetAccessLinkToOidIdentification
     * @request GET:/api/admin/kyc-record/{id}/swisscom/access_link
     * @secure
     */
    kycRecordControllerGetAccessLinkToOidIdentification: (id: string, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/admin/kyc-record/${id}/swisscom/access_link`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin/kyc-record
     * @name KycRecordControllerFindOidIdentificationHistory
     * @request GET:/api/admin/kyc-record/{id}/swisscom/history
     * @secure
     */
    kycRecordControllerFindOidIdentificationHistory: (id: string, params: RequestParams = {}) =>
      this.request<OIDIdentificationHistory[], any>({
        path: `/api/admin/kyc-record/${id}/swisscom/history`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin/kyc-record
     * @name KycRecordControllerCreateOidIdentificationHistory
     * @request POST:/api/admin/kyc-record/{id}/swisscom/history
     * @secure
     */
    kycRecordControllerCreateOidIdentificationHistory: (
      id: string,
      data: OIDIdentificationHistoryDto,
      params: RequestParams = {},
    ) =>
      this.request<OIDIdentificationHistory, any>({
        path: `/api/admin/kyc-record/${id}/swisscom/history`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin/kyc-record
     * @name KycRecordControllerFindDepositData
     * @summary Find transaction details of the last deposit (payin) done to the VA of the kyc record
     * @request GET:/api/admin/kyc-record/{id}/deposit_data
     * @secure
     */
    kycRecordControllerFindDepositData: (id: string, params: RequestParams = {}) =>
      this.request<DepositDataDto, any>({
        path: `/api/admin/kyc-record/${id}/deposit_data`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin/kyc-record
     * @name KycRecordControllerFindAllBankVerification
     * @request GET:/api/admin/kyc-record/{id}/bank_history
     * @secure
     */
    kycRecordControllerFindAllBankVerification: (id: string, params: RequestParams = {}) =>
      this.request<BankVerificationHistory[], any>({
        path: `/api/admin/kyc-record/${id}/bank_history`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin/kyc-record
     * @name KycRecordControllerCreateBankVerification
     * @request POST:/api/admin/kyc-record/{id}/bank_history
     * @secure
     */
    kycRecordControllerCreateBankVerification: (
      id: string,
      data: BankVerificationDto,
      params: RequestParams = {},
    ) =>
      this.request<BankVerificationHistory, any>({
        path: `/api/admin/kyc-record/${id}/bank_history`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin/kyc-record
     * @name KycRecordControllerFindAllAdminApproval
     * @request GET:/api/admin/kyc-record/{id}/admin_approval_history
     * @secure
     */
    kycRecordControllerFindAllAdminApproval: (id: string, params: RequestParams = {}) =>
      this.request<AdminApprovalHistory[], any>({
        path: `/api/admin/kyc-record/${id}/admin_approval_history`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin/kyc-record
     * @name KycRecordControllerCreateAdminApproval
     * @request POST:/api/admin/kyc-record/{id}/admin_approval_history
     * @secure
     */
    kycRecordControllerCreateAdminApproval: (
      id: string,
      data: AdminApprovalDto,
      params: RequestParams = {},
    ) =>
      this.request<AdminApprovalHistory, any>({
        path: `/api/admin/kyc-record/${id}/admin_approval_history`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin/kyc-record
     * @name KycRecordControllerFindAllOnlineIdentification
     * @request GET:/api/admin/kyc-record/{id}/online_identification_history
     * @secure
     */
    kycRecordControllerFindAllOnlineIdentification: (id: string, params: RequestParams = {}) =>
      this.request<OnlineIdentificationHistory[], any>({
        path: `/api/admin/kyc-record/${id}/online_identification_history`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin/kyc-record
     * @name KycRecordControllerCreateOnlineIdentification
     * @request POST:/api/admin/kyc-record/{id}/online_identification_history
     * @secure
     */
    kycRecordControllerCreateOnlineIdentification: (
      id: string,
      data: OnlineIdentificationDto,
      params: RequestParams = {},
    ) =>
      this.request<OnlineIdentificationHistory, any>({
        path: `/api/admin/kyc-record/${id}/online_identification_history`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin/kyc-record
     * @name KycRecordControllerFindAllIdentificationMeeting
     * @request GET:/api/admin/kyc-record/{id}/identification_meeting_history
     * @secure
     */
    kycRecordControllerFindAllIdentificationMeeting: (id: string, params: RequestParams = {}) =>
      this.request<IdentificationMeetingHistory[], any>({
        path: `/api/admin/kyc-record/${id}/identification_meeting_history`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin/kyc-record
     * @name KycRecordControllerCreateIdentificationMeeting
     * @request POST:/api/admin/kyc-record/{id}/identification_meeting_history
     * @secure
     */
    kycRecordControllerCreateIdentificationMeeting: (
      id: string,
      data: IdentificationMeetingDto,
      params: RequestParams = {},
    ) =>
      this.request<IdentificationMeetingHistory, any>({
        path: `/api/admin/kyc-record/${id}/identification_meeting_history`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin/kyc-record
     * @name KycRecordControllerFindAllMrzScannedData
     * @request GET:/api/admin/kyc-record/{id}/mrz_scanned_data_history
     * @secure
     */
    kycRecordControllerFindAllMrzScannedData: (id: string, params: RequestParams = {}) =>
      this.request<MrzScannedDataHistory[], any>({
        path: `/api/admin/kyc-record/${id}/mrz_scanned_data_history`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin/tasks
     * @name TasksControllerCreateOneTaskGroup
     * @request POST:/api/admin/tasks/groups
     * @secure
     */
    tasksControllerCreateOneTaskGroup: (data: CreateTaskGroupDto, params: RequestParams = {}) =>
      this.request<TaskGroup, any>({
        path: `/api/admin/tasks/groups`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin/tasks
     * @name TasksControllerFindTaskGroups
     * @request GET:/api/admin/tasks/groups
     * @secure
     */
    tasksControllerFindTaskGroups: (params: RequestParams = {}) =>
      this.request<TaskGroup[], any>({
        path: `/api/admin/tasks/groups`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin/tasks
     * @name TasksControllerFindOneTaskGroup
     * @request GET:/api/admin/tasks/groups/{taskGroupId}
     * @secure
     */
    tasksControllerFindOneTaskGroup: (taskGroupId: number, params: RequestParams = {}) =>
      this.request<TaskGroup, any>({
        path: `/api/admin/tasks/groups/${taskGroupId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin/tasks
     * @name TasksControllerCreateOneTask
     * @request POST:/api/admin/tasks
     * @secure
     */
    tasksControllerCreateOneTask: (data: CreateTaskDto, params: RequestParams = {}) =>
      this.request<Task, any>({
        path: `/api/admin/tasks`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin/tasks
     * @name TasksControllerFindAllTasks
     * @request GET:/api/admin/tasks
     * @secure
     */
    tasksControllerFindAllTasks: (params: RequestParams = {}) =>
      this.request<Task[], any>({
        path: `/api/admin/tasks`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin/tasks
     * @name TasksControllerFindOneTask
     * @request GET:/api/admin/tasks/{taskId}
     * @secure
     */
    tasksControllerFindOneTask: (taskId: number, params: RequestParams = {}) =>
      this.request<Task, any>({
        path: `/api/admin/tasks/${taskId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin/tenant
     * @name TenantControllerFindAll
     * @request GET:/api/admin/tenant
     * @secure
     */
    tenantControllerFindAll: (params: RequestParams = {}) =>
      this.request<TenantDto[], any>({
        path: `/api/admin/tenant`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin/tenant
     * @name TenantControllerCreateOne
     * @request POST:/api/admin/tenant
     * @secure
     */
    tenantControllerCreateOne: (data: TenantCreateDTO, params: RequestParams = {}) =>
      this.request<TenantDto, any>({
        path: `/api/admin/tenant`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin/task-profile
     * @name TaskProfileControllerCreateOne
     * @request POST:/api/admin/task-profile
     * @secure
     */
    taskProfileControllerCreateOne: (data: CreateTaskProfileDto, params: RequestParams = {}) =>
      this.request<TaskProfile, any>({
        path: `/api/admin/task-profile`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin/task-profile
     * @name TaskProfileControllerFindAll
     * @request GET:/api/admin/task-profile
     * @secure
     */
    taskProfileControllerFindAll: (
      query: TaskProfileControllerFindAllParams,
      params: RequestParams = {},
    ) =>
      this.request<TaskProfile[], any>({
        path: `/api/admin/task-profile`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin/task-profile
     * @name TaskProfileControllerFineOne
     * @request GET:/api/admin/task-profile/{profileType}
     * @secure
     */
    taskProfileControllerFineOne: (profileType: string, params: RequestParams = {}) =>
      this.request<TaskProfile, any>({
        path: `/api/admin/task-profile/${profileType}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin/task-profile
     * @name TaskProfileControllerVerifyKycRecord
     * @request GET:/api/admin/task-profile/verify/{profileType}
     * @secure
     */
    taskProfileControllerVerifyKycRecord: (profileType: string, params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/api/admin/task-profile/verify/${profileType}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin/task-record
     * @name TaskRecordControllerFindKycProfileRecord
     * @request GET:/api/admin/task-record
     * @secure
     */
    taskRecordControllerFindKycProfileRecord: (
      query: TaskRecordControllerFindKycProfileRecordParams,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/admin/task-record`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin/mrz
     * @name MrzControllerScanMrz
     * @request POST:/api/admin/mrz/{id}/scan
     * @secure
     */
    mrzControllerScanMrz: (
      id: string,
      data: {
        /** @format binary */
        file?: File;
      },
      params: RequestParams = {},
    ) =>
      this.request<IdentificationMeetingDto, any>({
        path: `/api/admin/mrz/${id}/scan`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: 'json',
        ...params,
      }),
  };
}
