import { useState, useEffect, useContext } from 'react';
import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { useAsync } from 'react-async-hook';
import dayjs from 'dayjs';

import { apiClient, MrzScannedDataHistory } from 'api';
import { Card } from 'components';
import { RefreshContext } from './RefreshContext';

const excludedFields = ['id', 'kycRecordId', 'valid', 'created', 'updated', 'rawMrz', 'fromFilename'];
const excludeFields = <T extends object>(object: T): Partial<T> => {
  return Object.keys(object)
    .filter((key) => !excludedFields.includes(key))
    .reduce((result, key) => {
      (result as T)[key as keyof T] = object[key as keyof T];
      return result;
    }, {} as Partial<T>);
};

export const MrzScannedDataHistoryWidget = () => {
  const { id, tenantId } = useParams<{ id: string; tenantId: string }>();

  const [history, setHistory] = useState<MrzScannedDataHistory[] | null>(null);

  const { loading, execute } = useAsync(async () => {
    const { data } = await apiClient.api.kycRecordControllerFindAllMrzScannedData(id, {
      headers: { 'x-tenant-id': tenantId },
    });

    setHistory(data as MrzScannedDataHistory[]);
  }, [id]);

  const { mrzRefresh } = useContext(RefreshContext);

  useEffect(() => {
    execute();
  }, [mrzRefresh]);

  return (
    <Grid item xs={12} id="mrz_scanned_data">
      <Card>
        <Card.Header title="Mrz scanned data" />
        <Card.Body
          loadingContent={loading}
          wrapperSx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 6,
          }}
        >
          {!!history?.length && (
            <Box>
              <Typography variant="subtitle2">Change history</Typography>
              <TableContainer sx={{ maxHeight: '500px' }}>
                <Table>
                  <TableHead>
                    <TableRow></TableRow>
                    <TableCell>File name</TableCell>
                    <TableCell>Document number</TableCell>
                    <TableCell>Full name</TableCell>
                    <TableCell>Valid</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Mrz code</TableCell>
                    <TableCell>Json data</TableCell>
                  </TableHead>
                  <TableBody>
                    {history?.map((h) => (
                      <TableRow>
                        <TableCell>{h.fromFilename}</TableCell>
                        <TableCell>{h.documentNumber}</TableCell>
                        <TableCell>{(h.firstName || '') + ' ' + (h.lastName || '')}</TableCell>
                        <TableCell>{h.valid === true ? 'true' : 'false'}</TableCell>
                        <TableCell>{dayjs(h.created).format('DD.MM.YYYY HH:mm')}</TableCell>
                        <TableCell>{h.rawMrz}</TableCell>
                        <TableCell>
                          <span
                            title={JSON.stringify(excludeFields(h), null, 2)}
                            style={{ cursor: 'help' }}
                          >
                            {JSON.stringify(excludeFields(h))}
                          </span>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          )}
        </Card.Body>
      </Card>
    </Grid>
  );
};
