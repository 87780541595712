import { useMemo, useState } from 'react';
import {
  Button,
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { Form, Formik, Field } from 'formik';
import { useAsync, useAsyncCallback } from 'react-async-hook';
import dayjs from 'dayjs';

import { OnlineIdentificationHistory, apiClient } from 'api';
import { Card } from 'components';
import { Select } from './form/Select';
import { OnlineIdentificationStatus } from 'types';
import { Input } from './form';
import { IdentificationMeetingHistoryWidget } from './IdentificationMeetingHistoryWidget';

const getOnlineIdentificationStatusOptions = () => {
  return Object.values(OnlineIdentificationStatus).map((val) => ({
    label: val,
    value: val,
  }));
};

export const OnlineIdentificationHistoryWidget = () => {
  const { id, tenantId } = useParams<{ id: string; tenantId: string }>();

  const [history, setHistory] = useState<OnlineIdentificationHistory[] | null>(null);

  const onlineIdentificationStatusOptions = useMemo(
    () => getOnlineIdentificationStatusOptions(),
    [],
  );

  const { loading, execute } = useAsync(async () => {
    const { data } = await apiClient.api.kycRecordControllerFindAllOnlineIdentification(id, {
      headers: { 'x-tenant-id': tenantId },
    });

    setHistory(data as OnlineIdentificationHistory[]);
  }, [id]);

  const update = useAsyncCallback(
    async ({ status, comment }: { status?: OnlineIdentificationStatus; comment?: string }) => {
      if (status) {
        await apiClient.api.kycRecordControllerCreateOnlineIdentification(
          id,
          { status, comment },
          {
            headers: { 'x-tenant-id': tenantId },
          },
        );
        await execute();
      }
    },
  );
  return (
    <Grid item xs={12}>
      <Card>
        <Card.Header title="Online identification" />
        <Card.Body
          loadingContent={loading}
          wrapperSx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 6,
          }}
        >
          <Formik
            enableReinitialize
            initialValues={{
              status: history?.[0]?.status as OnlineIdentificationStatus,
              comment: '',
            }}
            onSubmit={(values, { resetForm }) => {
              update.execute(values).then(() => {
                resetForm();
              });
            }}
          >
            <Form>
              <Box display="flex" flexDirection="row" alignItems="flex-end" gap={4}>
                <Field
                  id="status"
                  name="status"
                  label="Status"
                  placeholder="Please select"
                  component={Select}
                  options={onlineIdentificationStatusOptions}
                />
                <Field
                  id="comment"
                  name="comment"
                  label="Comment"
                  component={Input}
                />
                <Button type="submit">Save</Button>
              </Box>
            </Form>
          </Formik>
          {!!history?.length && (
            <Box>
              <Typography variant="subtitle2">Change history</Typography>
              <TableContainer sx={{ maxHeight: '500px' }}>
                <Table>
                  <TableHead>
                    <TableRow></TableRow>
                    <TableCell>Status</TableCell>
                    <TableCell>Comment</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Modified by</TableCell>
                  </TableHead>
                  <TableBody>
                    {history?.map((h) => (
                      <TableRow>
                        <TableCell>{h.status}</TableCell>
                        <TableCell>{h.comment}</TableCell>
                        <TableCell>{dayjs(h.created).format('DD.MM.YYYY HH:mm')}</TableCell>
                        <TableCell>{h.createdBy}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          )}
          <IdentificationMeetingHistoryWidget />
        </Card.Body>
      </Card>
    </Grid>
  );
};
